
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@500&display=swap');


.element {
    overflow-x: hidden;
  }


@tailwind base;
@tailwind components;
@tailwind utilities;




